@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        /* width */
        .small-scrollbar::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        .small-scrollbar::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        .small-scrollbar::-webkit-scrollbar-thumb {
            background: rgba(6, 5, 6, 0.7);
            border-radius: 5px;
        }

        /* Handle on hover */
        .small-scrollbar::-webkit-scrollbar-thumb:hover {
            background: #0F0C0F;
        }
    }
}
